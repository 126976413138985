import { Platform } from 'react-native';

const getImageSource = (source) => {
  return Platform.OS === 'web' ? { uri: source.default } : source;
};

const getCompressedImageUrl = (url: string) => {
  return url + '?x-oss-process=style/compress';
};

const LOGO = getImageSource(require('../assets/logo.png'));
const SLOGAN = getImageSource(require('../assets/slogan.png'));
const WECHAT = getImageSource(require('../assets/wechat.png'));
const HOME = getImageSource(require('../assets/home.png'));
const HOME_FOCUSED = getImageSource(require('../assets/home-focused.png'));
const HOME_BLURRED = getImageSource(require('../assets/home-blurred.png'));
const ORDER_FOCUSED = getImageSource(require('../assets/order-focused.png'));
const ORDER_BLURRED = getImageSource(require('../assets/order-blurred.png'));
const MY_PAGE_FOCUSED = getImageSource(
  require('../assets/my-page-focused.png'),
);
const MY_PAGE_BLURRED = getImageSource(
  require('../assets/my-page-blurred.png'),
);
const PERSONAL_CENTER_PROFILE = getImageSource(
  require('../assets/profile_picture.png'),
);
const PERSONAL_CENTER_MAIL = getImageSource(require('../assets/notice.png'));
const PERSONAL_ARROW = getImageSource(require('../assets/arrow.png'));
const PERSONAL_CENTER_WALLET_BG = getImageSource(
  require('../assets/wallet_bg.png'),
);
const PERSONAL_CENTER_WALLET_ICON = getImageSource(
  require('../assets/wallet.png'),
);
const ACC_INFO_SELFIE = getImageSource(require('../assets/logo.png'));
const RADIO_CHECK_CHECKED = getImageSource(require('../assets/selected.png'));
const RADIO_CHECK_EMPTY = getImageSource(require('../assets/select.png'));

const WEIXIN_ICON = getImageSource(require('../assets/wechatPay.png'));
const ZHIFUBAO_ICON = getImageSource(require('../assets/alipay.png'));
const MERCHANT_APPLY_SUCCESS = getImageSource(
  require('../assets/paymentSuccess.png'),
);
const ORDER_ITEM_LABEL_NOT_REFUNDED = getImageSource(
  require('../assets/label_not_refunded.png'),
);
const ORDER_ITEM_LABEL_CONGRAT = getImageSource(
  require('../assets/label_congrat.png'),
);
const ORDER_ITEM_LABEL_REFUNDED = getImageSource(
  require('../assets/label_refunded.png'),
);
const DELETE_ICON = getImageSource(require('../assets/delete_icon.png'));
const ORDER_LOGO = getImageSource(require('../assets/order_logo.png'));
const SEARCH_ICON = getImageSource(require('../assets/search.png'));
const CLOSE_ICON = getImageSource(require('../assets/close_icon.png'));
const DOWNLOAD_ICON = getImageSource(require('../assets/download_icon.png'));
const ORDER_PUBLIC_ICON = getImageSource(require('../assets/public.png'));
const ORDER_PRESALE_ICON = getImageSource(require('../assets/presale.png'));
const LABEL_RED = getImageSource(require('../assets/label_red.png'));
const LABEL_GREEN = getImageSource(require('../assets/label_green.png'));
const LABEL_BLACK = getImageSource(require('../assets/label_black.png'));
const NO_DATA = getImageSource(require('../assets/no_data.png'));
const SIMPLE_PLUS_BTN = getImageSource(require('../assets/add-icon.png'));
const MERCHANT_PROFILE_DEFAULT = getImageSource(require('../assets/logo.png'));
const CREATE_ARTICLE_FOCUSED = getImageSource(
  require('../assets/create-article-focused.png'),
);
const CREATE_ARTICLE_BLURRED = getImageSource(
  require('../assets/create-article-blurred.png'),
);
const MY_ARTICLE_FOCUSED = getImageSource(
  require('../assets/my-article-focused.png'),
);
const MY_ARTICLE_BLURRED = getImageSource(
  require('../assets/my-article-blurred.png'),
);
const ATTENTION = getImageSource(require('../assets/attention.png'));
const CALCULATOR = getImageSource(require('../assets/calculator.png'));
const ADD_TEXT = getImageSource(require('../assets/add-text.png'));
const ADD_IMAGE = getImageSource(require('../assets/add-image.png'));
const PERIOD_FOCUSED = getImageSource(require('../assets/period-focused.png'));
const PERIOD_BLURRED = getImageSource(require('../assets/period-blurred.png'));
const SUCCESS = getImageSource(require('../assets/success.png'));
const BACK_ICON = getImageSource(require('../assets/back-icon.png'));

const CUSTOMER_SERVICE = getImageSource(
  require('../assets/customer_service.png'),
);
const MORE_ICON = getImageSource(require('../assets/more.png'));
const COGS = getImageSource(require('../assets/cogs.png'));
const ADD_MATERIAL = getImageSource(require('../assets/add-material.png'));
const RECORD = getImageSource(require('../assets/record.png'));
const CUSTOMERS = getImageSource(require('../assets/customers.png'));
const ACCOUNT_BINDING = getImageSource(
  require('../assets/account-binding.png'),
);
const MERCHANT_SETTING = getImageSource(
  require('../assets/merchant-setting.png'),
);
const INCOME = getImageSource(require('../assets/income.png'));
const PREVIOUS = getImageSource(require('../assets/previous.png'));
const CUSTOMER_SERVICE_MERCHANT = getImageSource(
  require('../assets/customer-service-merchant.png'),
);
const QRCODE_BG = getImageSource(require('../assets/qrcode-bg.png'));
const RECORDS_BANNER = getImageSource(require('../assets/records-banner.png'));
const ACCOUNT_BINDING_BANNER = getImageSource(
  require('../assets/account-binding-banner.png'),
);
const MERCHANT_SETTING_BANNER = getImageSource(
  require('../assets/merchant-setting-banner.png'),
);
const RECORD_BACKGROUND = getImageSource(require('../assets/record-bg.png'));

const TAG_ICON = getImageSource(require('../assets/tag.png'));
const UPDATE_ICON = getImageSource(require('../assets/update.png'));
const NOTIFY_ICON = getImageSource(require('../assets/notify.png'));
const IMAGE_REMOVE_ICON = getImageSource(require('../assets/image-remove.png'));
const REMOVE_ROUND_ICON = getImageSource(require('../assets/remove.png'));
const DOWN_ICON = getImageSource(require('../assets/down.png'));
const LABEL_NOT_FOLDED = getImageSource(
  require('../assets/label_not_folded.png'),
);
const LABEL_FOLDED = getImageSource(require('../assets/label_folded.png'));
const ADD_ICON = getImageSource(require('../assets/add-icon.png'));

const WECHAT_SHARE_ICON = getImageSource(require('../assets/wechat-share.png'));
const WECHAT_MOMENT_ICON = getImageSource(
  require('../assets/wechat-moment.png'),
);
const MARSHALLING = getImageSource(require('../assets/marshalling3.png'));
const DEFAULT_CLIENT_AVATAR = getImageSource(
  require('../assets/default-avatar.png'),
);
const EXTENSION = getImageSource(require('../assets/promotion.png'));
const INVITATION = getImageSource(require('../assets/invite.png'));
const PROMOTION_WALLET = getImageSource(require('../assets/my-wallet.png'));
const BACK_ICON_02 = getImageSource(require('../assets/back_icon02.png'));
const X_CLOSE_ICON = getImageSource(require('../assets/xClose.png'));
const CLOSE = getImageSource(require('../assets/close.png'));
const BELL = getImageSource(require('../assets/bell.png'));
const SCAN = getImageSource(require('../assets/scan.png'));
const PHONE = getImageSource(require('../assets/phone.png'));
const CODE = getImageSource(require('../assets/code.png'));
const SPLASH_SCREEN = getImageSource(require('../assets/splash-screen.png'));

const WHITE_CLOSE_ICON = getImageSource(
  require('../assets/white-close-icon.png'),
);

const BOTTOM_LOGO_ICON = getImageSource(require('../assets/bottom_logo.png'));

const BUY_METHOD_BALANCE = getImageSource(
  require('../assets/buy_mothod_balance.png'),
);

const BUY_METHOD_RECHARGE = getImageSource(
  require('../assets/buy_mothod_recharge.png'),
);
const MESSAGE = getImageSource(require('../assets/message.png'));
const CLOUD = getImageSource(require('../assets/cloud.png'));

const PUSH_UTOP = getImageSource(require('../assets/uTop.png'));
const PUSH_U1135 = getImageSource(require('../assets/u1135.png'));
const DEFAULT_AVATAR = getImageSource(require('../assets/logo.png'));
const PLUS = getImageSource(require('../assets/plus.png'));
const GZH = getImageSource(require('../assets/gzh.png'));
const TOUSU = getImageSource(require('../assets/tousu.png'));
const EWM = getImageSource(require('../assets/ewm.png'));
const WECHAT_LOGIN = getImageSource(require('../assets/wechat_login.png'));
const CHAT_ICON = getImageSource(require('../assets/chat_icon.png'));
const PRIVATE_ICON = getImageSource(require('../assets/privacy_icon.png'));
/** 倒入优惠券背景图，有效/无效 */
const COUPON = getImageSource(require('../assets/coupon.png'));
const COUPON_INVALID = getImageSource(require('../assets/coupon_invalid.png'));
/** 文章购买优惠券选择图标 */
const COUPON_ICON = getImageSource(require('../assets/coupon_icon.png'));
/** 优惠券管理图标 */
const COUPON_MGR_ICON = getImageSource(
  require('../assets/coupon_mgr_icon.png'),
);

/** 优惠卡背景图 */
const DiscountCard_TYPE1_BG = getImageSource(
  require('../assets/discount_card_type1.png'),
);
const DiscountCard_TYPE2_BG = getImageSource(
  require('../assets/discount_card_type2.png'),
);
const DiscountCard_TYPE3_BG = getImageSource(
  require('../assets/discount_card_type3.png'),
);

const UP = getImageSource(require('../assets/up1.png'));

const DOWN = getImageSource(require('../assets/down1.png'));

const ARTICLE_QRCODE_BG = getImageSource(require('../assets/share/bg@2x.png'));
export default {
  getImageSource,
  getCompressedImageUrl,
  DEFAULT_AVATAR,
  CLOUD,
  LOGO,
  SLOGAN,
  WECHAT,
  HOME,
  HOME_FOCUSED,
  HOME_BLURRED,
  ORDER_FOCUSED,
  ORDER_BLURRED,
  MY_PAGE_FOCUSED,
  MY_PAGE_BLURRED,
  EXTENSION,
  INVITATION,
  PROMOTION_WALLET,
  PERSONAL_CENTER_PROFILE,
  PERSONAL_CENTER_MAIL,
  PERSONAL_ARROW,
  PERSONAL_CENTER_WALLET_BG,
  PERSONAL_CENTER_WALLET_ICON,
  ACC_INFO_SELFIE,
  RADIO_CHECK_CHECKED,
  RADIO_CHECK_EMPTY,
  WEIXIN_ICON,
  ZHIFUBAO_ICON,
  MERCHANT_APPLY_SUCCESS,
  ORDER_ITEM_LABEL_NOT_REFUNDED,
  ORDER_ITEM_LABEL_CONGRAT,
  DELETE_ICON,
  ORDER_ITEM_LABEL_REFUNDED,
  ORDER_LOGO,
  SEARCH_ICON,
  CLOSE_ICON,
  DOWNLOAD_ICON,
  ORDER_PUBLIC_ICON,
  ORDER_PRESALE_ICON,
  LABEL_RED,
  LABEL_GREEN,
  LABEL_BLACK,
  NO_DATA,
  MERCHANT_PROFILE_DEFAULT,
  CREATE_ARTICLE_FOCUSED,
  CREATE_ARTICLE_BLURRED,
  MY_ARTICLE_FOCUSED,
  MY_ARTICLE_BLURRED,
  ATTENTION,
  CALCULATOR,
  ADD_TEXT,
  ADD_IMAGE,
  PERIOD_FOCUSED,
  PERIOD_BLURRED,
  SUCCESS,
  BACK_ICON,
  CUSTOMER_SERVICE,
  MORE_ICON,
  COGS,
  ADD_MATERIAL,
  RECORD,
  CUSTOMERS,
  ACCOUNT_BINDING,
  MERCHANT_SETTING,
  INCOME,
  PREVIOUS,
  CUSTOMER_SERVICE_MERCHANT,
  QRCODE_BG,
  RECORDS_BANNER,
  ACCOUNT_BINDING_BANNER,
  MERCHANT_SETTING_BANNER,
  TAG_ICON,
  NOTIFY_ICON,
  UPDATE_ICON,
  RECORD_BACKGROUND,
  IMAGE_REMOVE_ICON,
  DOWN_ICON,
  LABEL_NOT_FOLDED,
  LABEL_FOLDED,
  REMOVE_ROUND_ICON,
  SIMPLE_PLUS_BTN,
  ADD_ICON,
  WECHAT_SHARE_ICON,
  WECHAT_MOMENT_ICON,
  MARSHALLING,
  DEFAULT_CLIENT_AVATAR,
  X_CLOSE_ICON,
  BACK_ICON_02,
  SCAN,
  PHONE,
  CODE,
  SPLASH_SCREEN,
  WHITE_CLOSE_ICON,
  BOTTOM_LOGO_ICON,
  BUY_METHOD_BALANCE,
  BUY_METHOD_RECHARGE,
  MESSAGE,
  CLOSE,
  BELL,
  PUSH_UTOP,
  PUSH_U1135,
  PLUS,
  GZH,
  EWM,
  TOUSU,
  WECHAT_LOGIN,
  CHAT_ICON,
  PRIVATE_ICON,
  ARTICLE_QRCODE_BG,
  COUPON_MGR_ICON,
  COUPON,
  COUPON_INVALID,
  COUPON_ICON,
  DiscountCard_TYPE1_BG,
  DiscountCard_TYPE2_BG,
  DiscountCard_TYPE3_BG,
  DOWN,
  UP
};
