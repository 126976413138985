/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description:
 */
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeTabNavigation from './Home';
import {
  OrderDetailScreen,
  BalancePaymentScreen,
  PaymentSuccessScreen,
  ApplySubmitSuccessScreen,
  EventMaterial,
  AgreementScreen,
  SuggestScreen,
  FollowQrCode,
  AboutUsScreen,
  AccInfoScreen,
  AlipayAccountClientBindScreen,
  ApplySubmitWin,
  Attention,
  BankCard,
  CardNumberScreen,
  Coupon,
  DisclaimScreen,
  DiscountCard,
  DisputeResolveScreen,
  EnterScreen,
  Extension,
  FeedbackScreen,
  Invitation,
  MerchantApplyScreen,
  ModContNumberScreen,
  MyPageScreen,
  PrivacyAgreementScreen,
  PromotionWallet,
  PushGuideScreen,
  RealnameVerify,
  Rule,
  SuccessScreen,
  TopUpScreen,
  UserAgreementScreen,
  ValidationSuccess,
  WalletDetailsScreen,
  WithdrawalScreen,
  onRefund,
  OrderScreen,
  ClientOrderDetailScreen,
  DisableUserScreen,
} from '../screens';
import { useSelector } from 'react-redux';
import { colors } from '../utils';
import Watermark from '@pansy/react-watermark';

const Stack = createStackNavigator();

const MainNavigator = () => {
  const currentClient = useSelector((state: any) => state.client.currentClient);

  return (
    <>
      <Stack.Navigator
        screenOptions={{
          headerStyle: {
            backgroundColor: colors.GUARDSMAN_RED,
          },
          headerTintColor: colors.WHITE,
          headerBackTitleVisible: false,
          headerTitleAlign: 'center',
          headerShown: false,
        }}>
        <Stack.Screen
          name="Home"
          component={HomeTabNavigation}
          options={{
            title: '首页',
          }}
        />
        <Stack.Screen
          name="OrderDetail"
          component={OrderDetailScreen}
          options={{
            title: '文章详情',
          }}
        />
        <Stack.Screen
          name="OrderDetail1"
          component={OrderDetailScreen}
          options={{
            title: '文章详情',
          }}
        />
        <Stack.Screen
          name="BalancePayment"
          component={BalancePaymentScreen}
          options={{
            title: '文章购买支付',
          }}
        />
        <Stack.Screen
          name="PaymentSuccess"
          component={PaymentSuccessScreen}
          options={{
            title: '购买成功',
          }}
        />
        <Stack.Screen
          name="ApplySubmitSuccess"
          component={ApplySubmitSuccessScreen}
          options={{
            title: '商家申请提交成功',
          }}
        />
        <Stack.Screen
          name="Agreement"
          component={AgreementScreen}
          options={{
            title: '充值和购买服务协议',
            headerTitle: '充值和购买服务协议',
          }}
        />
        <Stack.Screen
          name="EventMaterial"
          component={EventMaterial}
          options={{
            title: '',
            headerTitle: '',
          }}
        />
        <Stack.Screen
          name="Suggest"
          component={SuggestScreen}
          options={{
            title: '投诉商家',
          }}
        />
        <Stack.Screen
          name="FollowQrCode"
          component={FollowQrCode}
          options={{
            title: '主页码',
          }}
        />
        <Stack.Screen
          name="index"
          component={MyPageScreen}
          options={{
            title: '我的',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="AccInfo"
          component={AccInfoScreen}
          options={{
            title: '账号信息',
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="AlipayAccountClientBind"
          component={AlipayAccountClientBindScreen}
          options={{
            title: '绑定支付宝账号',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ModContNumber"
          component={ModContNumberScreen}
          options={{
            title: currentClient.phoneNumber ? '修改手机号' : '绑定手机号',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="OrderScreen"
          component={OrderScreen}
          options={{
            title: '订单',
            headerShown: true,
          }}
        />
        <Stack.Screen
          name="ClientOrderDetail"
          component={ClientOrderDetailScreen}
          options={{
            title: '文章详情-已购',
            headerShown: false,
            headerStyle: {
              backgroundColor: colors.GUARDSMAN_RED,
            },
            headerTintColor: colors.WHITE,
            headerBackTitleVisible: false,
            headerTitleAlign: 'center',
          }}
        />

        <Stack.Screen name="Success" component={SuccessScreen} />
        <Stack.Screen
          name="Withdrawal"
          component={WithdrawalScreen}
          options={{
            title: '提现',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Wallet"
          component={WalletDetailsScreen}
          options={{
            title: '钱包明细',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="onRefund"
          component={onRefund}
          options={{
            title: '退款',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ApplySubmitWin"
          component={ApplySubmitWin}
          options={{
            title: '商家入驻',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="MerchantApply"
          component={MerchantApplyScreen}
          options={{
            title: '商家申请',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="enter"
          component={EnterScreen}
          options={{
            title: '商家入驻协议',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TopUp"
          component={TopUpScreen}
          options={{
            title: '充值',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Extension"
          component={Extension}
          options={{
            title: '推广商家',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Rule"
          component={Rule}
          options={{
            title: '活动规则',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="RealnameVerify"
          component={RealnameVerify}
          options={{
            title: '实名认证',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="BankCard"
          component={BankCard}
          options={{
            title: '绑定银行卡',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="CardNumber"
          component={CardNumberScreen}
          options={{
            title: '银行卡',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ValidationSuccess"
          component={ValidationSuccess}
          options={{
            title: '',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="invitation"
          component={Invitation}
          options={{
            title: '我的邀请',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="attention"
          component={Attention}
          options={{
            title: '他的关注',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="promotionWallet"
          component={PromotionWallet}
          options={{
            title: '推广钱包',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DisputeResolve"
          component={DisputeResolveScreen}
          options={{
            title: '纠纷解决',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Disclaim"
          component={DisclaimScreen}
          options={{
            title: '免责说明',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Coupon"
          component={Coupon}
          options={{
            title: '我的优惠券',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DiscountCard"
          component={DiscountCard}
          options={{
            title: '我的优惠卡',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="PushGuide"
          component={PushGuideScreen}
          options={{
            title: '消息通知设置教程',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="UserAgreement"
          component={UserAgreementScreen}
          options={{
            title: '隐私协议',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="PrivacyAgreement"
          component={PrivacyAgreementScreen}
          options={{
            title: '隐私协议',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Feedback"
          component={FeedbackScreen}
          options={{
            title: '意见反馈',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="AboutUs"
          component={AboutUsScreen}
          options={{
            title: '关于我们',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DisableUser"
          component={DisableUserScreen}
          options={{ title: '系统网络错误' }}
        />
      </Stack.Navigator>
      <Watermark
        text={currentClient.onlyKey}
        fontSize={30}
        width={200}
        height={200}
        opacity={0.05}
        pack={false}
      />
    </>
  );
};

export default MainNavigator;
